<template>
  <el-dialog title="提示" :visible.sync="dialogVisible" width="60%" :before-close="handleClose">
    <el-form v-model="commonUserForm" label-width="100px">
      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="登录账号 " width="120px">
            <el-input width="20px" v-model="commonUserForm.userName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="昵称 " width="120px">
            <el-input width="20px" v-model="commonUserForm.nickName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="真实姓名 " width="120px">
            <el-input width="20px" v-model="commonUserForm.realName"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="用户类型" width="120px">
            <el-input width="20px" v-model="commonUserForm.userType"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="行业 " width="120px">
            <el-input width="20px" v-model="commonUserForm.industryCode"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="性别 " width="120px">
            <el-input width="20px" v-model="commonUserForm.sex"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="电话 " width="120px">
            <el-input width="20px" v-model="commonUserForm.phone"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="账号类型" width="120px">
            <el-input width="20px" v-model="commonUserForm.accountType"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="部门id " width="120px">
            <el-input width="20px" v-model="commonUserForm.deptId"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="岗位:不开放" width="120px">
            <el-input width="20px" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="备注 " width="120px">
            <el-input width="20px" v-model="commonUserForm.remark"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirmButton">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {
  selectuserByIdApi,
  deleteuserByIdApi,
  edituserApi,
  adduserApi,
  listuserApi
} from "@/api/system/user/user.js";
export default {
  name: "commonUserForm",
  data() {
    return {
      dialogVisible: false,
      commonUserForm: {
        id: null,
        userSign: null,
        userName: null,
        nickName: null,
        realName: null,
        password: null,
        userCode: null,
        userType: null,
        industryCode: null,
        avatar: null,
        birthday: null,
        sex: null,
        provinceCode: null,
        cityCode: null,
        areaCode: null,
        address: null,
        weixinCode: null,
        qqCode: null,
        email: null,
        phone: null,
        thirdId: null,
        thirdType: null,
        payload: null,
        systemSource: null,
        accountType: null,
        status: null,
        deptId: null,
        isDelect: null,
        remark: null,
        createdBy: null,
        createdTime: null,
        updatedBy: null,
        updatedTime: null
      },
      editShow: false,
      ids: null
    };
  },
  methods: {
    //打开弹窗
    show(id, type) {
      this.dialogVisible = true;
      this.ids = id;
      this.types = type;
    },
    cancel() {
      this.$emit("closeModal", false);
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    confirmButton() {}
  }
};
</script>
<style lang="less" scoped>
</style>