<template>
  <div>
    <!-- 导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>生活管理</el-breadcrumb-item>
      <el-breadcrumb-item>居家管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片试图 -->
    <el-card>
      <el-row :gutter="20">
        <el-col :span="7">
          <span>请求参数：</span>
          <el-input
            placeholder
            style="width: 60%"
            v-model="queryParams.name"
          ></el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primay" @click="commonUserListButton()"
            >查询</el-button
          >
          <el-button type="primay">重置</el-button>
        </el-col>
      </el-row>
      <!-- 按钮区 -->
      <el-row :gutter="20" style="margin: 9px 2px">
        <el-button
          type="warning"
          icon="el-icon-add"
          size="min"
          @click="addCommonUserButton()"
          >新增</el-button
        >
      </el-row>
      <!-- 列表区域 -->

      <el-table :data="commonUserDataList" border stripe>
        <el-table-column label="主键" prop="id" />
        <el-table-column label="用户标识" prop="userSign" />
        <el-table-column label="登录账号" prop="userName" />
        <el-table-column label="昵称" prop="nickName" />
        <el-table-column label="用户编码" prop="userCode" />
        <el-table-column label="用户类型" prop="userType" />
        <el-table-column label="行业" prop="industryCode" />
        <el-table-column label="头像" prop="avatar" />
        <el-table-column label="常用地址" prop="address" />
        <el-table-column label="账号来源" prop="systemSource" />
        <el-table-column label="状态" prop="status" />
        <el-table-column label="部门" prop="deptId" />
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              disabled=""
              @click="editCommonUser(scope.$index, scope.row)"
              >修改</el-button
            >
            <el-button
              size="mini"
              type="text"
              icon="el-icon-setting"
              @click="configRoleButton(scope.$index, scope.row)"
              >配置角色</el-button
            >
            <el-button
              size="mini"
              type="text"
              icon="el-icon-setting"
              disabled=""
              @click="selectCommonUserButton(scope.$index, scope.row)"
              >添加子用户</el-button
            >
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              disabled=""
              @click="selectCommonUserButton(scope.$index, scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
      :total="queryParams.total"
      :page-size.sync="queryParams.pageSize"
      :current-page.sync="queryParams.currentPage"
    />
    </el-card>
    <CommonUserForm ref="commonUserModules"></CommonUserForm>
    <ConfigRoleFrom ref="ConfigRoleModuels" />
  </div>
</template>

<script>
import CommonUserForm from "./modules/userForm";
import ConfigRoleFrom from "./modules/configRole";
import { listuserApi } from "@/api/system/user/user.js";
export default {
  name: "commonUser",
  components: {
    CommonUserForm,
    ConfigRoleFrom,
  },
  data() {
    return {
      radio: null,
      commonUserDataList: [],
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  created() {
    this.commonUserListButton();
  },
  watch: {
    "queryParams.currentPage"(val) {
      this.commonUserListButton();
    },
    "queryParams.pageSize"(val) {
      this.commonUserListButton();
    },
  },
  methods: {
    commonUserListButton() {
      listuserApi(this.queryParams).then((res) => {
        this.commonUserDataList = res.result.records;
        this.queryParams.total = res.result.total;
      });
    },
    addCommonUserButton() {
      this.$refs.commonUserModules.show(null, 2);
    },
    editCommonUser(index, row) {
      this.$refs.commonUserModules.show(row.id, 2);
    },
    delectCommonUserButton(index, row) {},
    selectCommonUserButton(index, row) {},
    configRoleButton(index, row) {
      this.$refs.ConfigRoleModuels.show(row);
    },
  },
};
</script>

<style lang="less" scoped>
</style>